@import "../../shared/styles/partials/breakpoints";
@import "../../shared/styles/partials/fonts";
@import "../../shared/styles/partials/colors";
@import "../../shared/styles/partials/variables";

.SitterSignup {
	width: 100%;
	max-width: $max-width-container--withOffset;
	height: 100%;
	margin: 0 auto;
	box-sizing: border-box;
	min-height: 400px;
}
