@import "../../shared/styles/partials/breakpoints";
@import "../../shared/styles/partials/fonts";
@import "../../shared/styles/partials/colors";
@import "../../shared/styles/partials/variables";

.ServicesAndRates {
	position: relative;
	margin: 0 auto;
	width: 100%;
	flex-shrink: 0;
	min-height: 400px;
}

.ServicesAndRates-content {
	position: relative;
}

.ServicesAndRates-wrapper {
	> ul {
		list-style: none;
		margin: 0;
		padding: 0;

		> li {
			margin: 0 0 30px;
		}
	}
}
