@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap');
$font-base: "Nunito", "Helvetica Neue", "Helvetica","Arial", sans-serif;

@mixin font-base(
	$size: false,
	$colour: false,
	$weight: false,
	$lh: false,
	$ls: false
) {
	font-family: $font-base;

	@if $size {
		font-size: $size;
	}

	@if $colour {
		color: $colour;
	}

	@if $weight {
		font-weight: $weight;
	}

	@if $lh {
		line-height: $lh;
	}

	@if $ls {
		letter-spacing: $ls + px;
	}
}
