@import "../../shared/styles/partials/breakpoints";
@import "../../shared/styles/partials/fonts";
@import "../../shared/styles/partials/colors";
@import "../../shared/styles/partials/variables";

.Notifications {
	position: relative;

	> section {
		margin: 0 0 50px;
	}
}
