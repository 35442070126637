@import "../../shared/styles/partials/breakpoints";
@import "../../shared/styles/partials/fonts";
@import "../../shared/styles/partials/colors";
@import "../../shared/styles/partials/variables";

.Service {
	position: relative;
	border-radius: 8px;
	border: 1px solid $color-grey--border;
	padding: 24px;
	box-sizing: border-box;
	width: 100%;
	box-shadow: $default-box-shadow;

	@include tablet {
		padding: 24px 30px;
	}

	&.Service--noBottomPadding {
		padding-bottom: 0;
	}
}

.Service-content {
	position: relative;

	@include tablet {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}

.Service-description {
	margin: 15px 0 0;
	@include font-base(0.875rem, $color-black, 400, 1.188rem);
	letter-spacing: 0.5px;
}

.Service-additional {
	position: relative;

	> button {
		@include font-base(0.875rem, $color-green, 400, 1rem);
		background-color: transparent;
		margin: 15px 0 24px;
		padding: 0;
		border: 0;
		cursor: pointer;

		&:before {
			content: "+";
			margin: 0 4px 0 0;
			display: inline-flex;
			transform: translateY(-1px);
			font-size: 1rem;
			line-height: 1rem;
		}

		&:focus {
			outline: 0;
			box-shadow: none;
		}
	}
}

.Service-accordion {
	position: relative;
	overflow: hidden;
	transition: height .3s ease-in-out;
	height: 0;
	box-sizing: border-box;
	list-style: none;
	padding: 0;
	margin: 0;

	> li {
		margin: 0 0 20px;
		display: flex;
		justify-content: space-between;

		&:last-child {
			margin: 0;
		}
	}
}

.Service-field {
	margin: 20px 0 0;

	@include tablet {
		margin: 0;

		> div {
			width: 150px;
		}
	}
}
