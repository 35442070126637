@mixin input-placeholder {
  &.placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }

  &::-webkit-input-placeholder {
    @content;
  }
}

@mixin background-opacity($color, $opacity: 0.3) {
  background: $color;
  /* The Fallback */
  background: rgba($color, $opacity);
}

@mixin color-opacity($color, $opacity: 0.3) {
  color: rgba($color, $opacity);
}

@mixin border($top, $right, $bottom, $left, $type, $color) {
  border: $top $right $bottom $left $type $color;
}

@mixin keyframe-infinite-spinning {
	0% {
		transform: rotate3d(0, 0, 1, 0deg);
	}
	25% {
		transform: rotate3d(0, 0, 1, 90deg);
	}
	50% {
		transform: rotate3d(0, 0, 1, 180deg);
	}
	75% {
		transform: rotate3d(0, 0, 1, 270deg);
	}
	100% {
		transform: rotate3d(0, 0, 1, 360deg);
	}
}


@keyframes infiniteSpinning {
	@include keyframe-infinite-spinning;
}

@mixin infiniteSpinning() {
	$keyframe-name: infiniteSpinning;
	animation: $keyframe-name 600ms normal linear infinite;
}


@mixin keyframe-fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fadeIn {
	@include keyframe-fadeIn;
}

@mixin fadeIn($arg) {
	$keyframe-name: fadeIn;
	$duration: $arg;
	animation: $keyframe-name $duration;
}

@mixin keyframe-fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@keyframes fadeOut {
	@include keyframe-fadeOut;
}

@mixin fadeOut($arg) {
	$keyframe-name: fadeOut;
	$duration: $arg;
	animation: $keyframe-name $duration;
}
