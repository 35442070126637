@import "../../shared/styles/partials/breakpoints";
@import "../../shared/styles/partials/fonts";
@import "../../shared/styles/partials/colors";
@import "../../shared/styles/partials/variables";

.LoginAndSecurity {
	position: relative;
	font-size: 1.5rem;
	font-weight: 500;
	margin: 0 auto;
	width: 100%;
	flex-shrink: 0;
	color: $color-orange;
}

.LoginAndSecurity-content {
	margin: 50px 0 0;
}
