@import "../../shared/styles/partials/colors";
@import "../../shared/styles/partials/fonts";
@import "../../shared/styles/partials/breakpoints";

.Group {
	position: relative;
	width: 100%;
	margin: 0 0 30px;

	@include mobile {
		&.Group--50 {
			.Group-content {
				> * {
					width: calc(50% - 10px);
				}
			}
		}

		&.Group--33 {
			.Group-content {
				> * {
					width: calc(33% - 10px);
				}
			}
		}
	}

	@include tablet {
		margin: 0 0 40px;
	}
}

.Group-content {
	position: relative;

	> * {
		margin: 0 0 20px;

		&:last-child {
			margin: 0;
		}
	}

	@include mobile {
		display: flex;
		justify-content: space-between;

		> * {
			width: 100%;
			margin: 0;
		}
	}
}
