@import "../../shared/styles/partials/colors";
@import "../../shared/styles/partials/fonts";

.Textarea {
	position: relative;
	margin: 0 0 40px;

	&:last-child {
		margin: 0
	}

	&.is-focused {
		.Textarea-data {
			border-color: $color-green;
		}
	}
}

.Textarea-data {
	border: 1px solid $color-grey--input;
	padding: 10px 24px;
	box-sizing: border-box;
	border-radius: 6px;
	display: flex;
	flex-flow: column;
	transition: all 300ms;

	> label {
		display: block;
		@include font-base(0.688rem, $color-black, 400, 1rem);
		letter-spacing: 0.39px;
	}

	> textarea {
		-webkit-appearance: none;
		border: 0;
		padding: 0;
		margin: 3px 0 0;
		@include font-base(1rem, $color-black, 500, 1rem);
		letter-spacing: 0.57px;
		resize: vertical;

		&:focus {
			outline: 0;
			box-shadow: none;
		}

		/* Chrome, Safari, Edge, Opera */
		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		/* Firefox */
		&[type=number] {
			-moz-appearance: textfield;
		}
	}

	&.Textarea-data--error {
		border-color: $color-red;
		background-color: $color-pink;
		transition: border-color 300ms;

		> textarea {
			background-color: $color-pink;
		}

		> label {
			color: $color-red;
			transition: color 300ms;
		}
	}
}

.Textarea-feedback {
	@include font-base(0.875rem, $color-red, 500);
	letter-spacing: 0.5px;
	display: flex;
	align-items: center;
	width: 100%;
	min-height: 0;
	overflow: hidden;

	&.is-visible {
		min-height: 20px;
		margin: 8px 0;
	}

	> img {
		margin: 0 5px 0 0;
	}
}

