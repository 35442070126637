@import "../../shared/styles/partials/colors";
@import "../../shared/styles/partials/variables";

.Header {
	height: 80px;
	background-color: $color-white;
	border-bottom: 1px solid $color-grey--border;
}

.Header-content {
	width: 100%;
	max-width: $max-width-container--withOffset;
	height: 100%;
	margin: 0 auto;
	box-sizing: border-box;
	padding: 0 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.Header-logo {
	font-size: 2rem;
	font-weight: 600;
	color: $color-green;
}
