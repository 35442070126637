@import "../../shared/styles/partials/colors";
@import "../../shared/styles/partials/fonts";

.Field {
	position: relative;
	display: flex;
	align-items: center;
	border-bottom: 1px solid $color-grey--border;
	padding: 0 0 15px;
	box-sizing: border-box;
	width: 100%;
	justify-content: space-between;
	margin: 0 0 30px;

	&.Field--readOnly {
		border: 0;

		.Field-data {
			> label {
				font-weight: 700;
				font-size: 1.25rem;
				letter-spacing: 0.28px;
			}
		}
	}
}

.Field-data {
	position: relative;
	> label {
		@include font-base(1rem, $color-black, 600);
		letter-spacing: 0.44px;
		display: block;
		margin: 0 0 10px;
	}

	> span {
		@include font-base(0.875rem, $color-black, 400);
		letter-spacing: 0.44px;
		display: block;
	}
}

.Field-form {
	position: relative;
	width: 100%;

	> footer {
		display: flex;
		justify-content: flex-end;
	}
}
