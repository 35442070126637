@import "../../shared/styles/partials/colors";
@import "../../shared/styles/partials/fonts";

.Title {
	@include font-base(2rem, $color-grey--text, 700, 2rem, 0.48);
	margin: 0;

	&.is-bold {
		font-weight: 700;
	}

	&.is-green {
		color: $color-green;
	}

	&.is-red {
		color: $color-red;
	}

	&.is-orange {
		color: $color-orange;
	}

	&.is-white {
		color: $color-white;
	}

	&.is-black {
		color: $color-black;
	}
}
