@import "../../shared/styles/partials/breakpoints";
@import "../../shared/styles/partials/fonts";
@import "../../shared/styles/partials/colors";
@import "../../shared/styles/partials/variables";

.PersonalInfo {
	position: relative;
	font-size: 1.5rem;
	font-weight: 500;
	margin: 0 auto;
	width: 100%;
	flex-shrink: 0;
	min-height: 400px;
	color: $color-orange;
}

.PersonalInfo-photo {
	margin: 0 0 30px;
}
