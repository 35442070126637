@import "../../shared/styles/partials/breakpoints";
@import "../../shared/styles/partials/fonts";
@import "../../shared/styles/partials/colors";
@import "../../shared/styles/partials/variables";

.ServicesAvailability {
	position: relative;
	margin: 0 auto;
	width: 100%;
	flex-shrink: 0;
	min-height: 400px;
}

.ServicesAvailability-content {
	position: relative;

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
	}
}

.ServicesAvailability-description {
	margin: 0 0 30px;
	@include font-base(1.25rem, $color-black, 400);
	letter-spacing: 0.63px;
}

.ServicesAvailabilityBlock {
	position: relative;
	margin: 0 0 60px;
}

.ServicesAvailabilityBlock-name {
	position: relative;
	border-bottom: 1px solid $color-grey--border;
	padding: 0 0 25px;
}

.ServicesAvailabilityBlock-content {
	padding: 25px 0 0;
}

.ServicesAvailabilityBlock-question {
	@include font-base(1.125rem, $color-grey--text, 700);
	margin: 0 0 20px;
}

.ServicesAvailabilityBlock-days {
	display: flex;
	flex-wrap: wrap;
	margin: 0 0 10px;

	> div {
		margin: 0 20px 20px 0;
	}
}
