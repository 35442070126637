@import "../../shared/styles/partials/breakpoints";
@import "../../shared/styles/partials/fonts";
@import "../../shared/styles/partials/colors";
@import "../../shared/styles/partials/variables";

.PayoutMethod {
	position: relative;
	margin: 0 auto;
	width: 100%;
	flex-shrink: 0;
	min-height: 400px;
}

.PayoutMethod-content {
	position: relative;
}

.PayoutMethod-list {
	position: relative;
	margin: 30px 0;
}
