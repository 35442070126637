@import "../../shared/styles/partials/breakpoints";
@import "../../shared/styles/partials/fonts";
@import "../../shared/styles/partials/colors";
@import "../../shared/styles/partials/variables";

.InnerContent {
	position: relative;
	max-width: $max-width-content;
	width: 100%;
}

.InnerContent-overallText {
	display: none;

	@include desktop {
		@include font-base(1.125rem, $color-black, 700, 1.75rem);
		letter-spacing: 0.45px;
		margin: 0 0 50px;
		position: relative;
		display: block;
	}
}

.InnerContent-title {
	@include font-base(1.125rem, $color-black, 700, 1.25rem, 0.45px);
	margin: 0 0 25px;
	position: relative;

	@include desktop {
		@include font-base(2rem, $color-black, 700, 2.25rem, 0.48);
	}
}

.InnerContent-description {
	@include font-base(0.875rem, $color-black, 400, 1.5rem, 0.5);
	margin: 0 0 30px;
	position: relative;

	@include tablet {
		margin: 0 0 45px;
		@include font-base(1.125rem, $color-black, 400, 1.875rem, 0.5);
	}
}

.InnerContent-footer {
	display: flex;
	justify-content: space-between;
	margin: 40px 0;

	> button {
		width: calc(50% - 10px);

		@include desktop {
			width: fit-content;
		}
	}
}
