@import "../../shared/styles/partials/breakpoints";
@import "../../shared/styles/partials/fonts";
@import "../../shared/styles/partials/colors";
@import "../../shared/styles/partials/variables";

.MyPet {
	position: relative;
	font-size: 1.5rem;
	font-weight: 500;
	margin: 0 auto;
	width: 100%;
	flex-shrink: 0;
	min-height: 400px;
	color: $color-orange;
}

.MyPet-switches {
	margin: 0 0 20px;
}

.MyPet-fields {
	position: relative;
	padding: 40px 0 ;
}

.MyPet-footer {
	display: flex;
	justify-content: flex-end;
	margin: 40px 0 0;

	> button {
		margin: 0 0 0 20px;
	}
}
