@import "../../shared/styles/partials/colors";
@import "../../shared/styles/partials/fonts";
@import "../../shared/styles/partials/breakpoints";

.Suggestion {
	@include font-base(0.75rem, $color-black, 400, 1.375rem, 0.5);
	letter-spacing: 0.5px;
	display: block;
	margin: 10px 0 0;

	@include tablet {
		@include font-base(0.875rem, $color-black, 400);
	}
}
