@import "../../shared/styles/partials/breakpoints";
@import "../../shared/styles/partials/fonts";
@import "../../shared/styles/partials/colors";
@import "../../shared/styles/partials/variables";

.MyPets {
	position: relative;

	@include mobile {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-column-gap: 20px;
		grid-row-gap: 20px;
	}
}
