@import "../../shared/styles/partials/colors";
@import "../../shared/styles/partials/fonts";
@import "../../shared/styles/partials/variables";
@import "../../shared/styles/partials/breakpoints";
@import "../../shared/styles/partials/utilities";

.FilePreview {
	position: relative;
	width: 100%;
	border-radius: 16px;
	overflow: hidden;
	display: inline-flex;
	height: 240px;
	box-sizing: border-box;
	margin: 0 0 20px;
	cursor: pointer;
	border: 1px solid $color-grey--box;
	flex-shrink: 0;

	@include mobile {
		margin: 0;
	}

	@include large-desktop {
		&:hover {
			.FilePreview-overlay {
				@include background-opacity($color-black, 0.5);
			}
		}
	}
}

.FilePreview-background {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 16px;
	z-index: 1;
}

.FilePreview-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 16px;
	transition: background-color 300ms;
	@include background-opacity($color-black, 0.6);
	z-index: 2;
}

.FilePreview-image {
	object-fit: cover;
	width: 100%;
	height: 100%;
	border-radius: 16px;
	position: absolute;
	top: 0;
	left: 0;
}

.FilePreview-data {
	z-index: 3;
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	display: flex;
	flex-flow: column;
	justify-content: flex-end;
}

.FilePreview-name {
	@include font-base(1.5rem, $color-white, 700);
	letter-spacing: 0.38px;
	margin: 0 16px 16px;

	@include large-desktop {
		margin: 0 24px 16px;
	}
}

.FilePreview-footer {
	background-color: $color-white;
	height: 60px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 16px;
	box-sizing: border-box;

	@include large-desktop {
		padding: 0 24px;
	}

	> div {
		margin: 0 0 0 auto;
		display: flex;
		align-items: center;
	}
}

.FilePreview-remove {
	background-color: transparent;
	border: 0;
	padding: 0;
	@include font-base(1rem, $color-red, 600);
}

.FilePreview-button {
	background-color: transparent;
	border: 0;
	padding: 0;
	margin: 0 10px 0 0;
	text-decoration: none;

	&:last-child {
		margin: 0;
	}

	display: flex;
	align-items: center;
	@include font-base(1rem, $color-green, 600);
}
