@import "../../shared/styles/partials/fonts";
@import "../../shared/styles/partials/colors";
@import "../../shared/styles/partials/breakpoints";

.CheckboxGroup {
	position: relative;
	margin: 0 0 40px;
}

.CheckboxGroup-title {
	margin: 0 0 15px;
	@include font-base(1rem, $color-black, 400);
	letter-spacing: 0.5px;

	@include tablet {
		@include font-base(1.125rem, $color-black, 700);
		margin: 0 0 20px;
	}
}

.CheckboxGroup-description {
	margin: 0 0 20px;
	@include font-base(0.875rem, $color-black, 400);
	letter-spacing: 0.43px;
}

.CheckboxGroup-list {
	list-style: none;
	margin: 0;
	padding: 0;

	li {
		margin: 0 0 20px;

		&:last-child {
			margin: 0;
		}
	}
}
