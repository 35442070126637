@import "../../shared/styles/partials/colors";
@import "../../shared/styles/partials/fonts";
@import "../../shared/styles/partials/breakpoints";

.Grid {
	position: relative;
}

.Grid-title {
	margin: 0 0 25px;
	@include font-base(1rem, $color-black, 600);
	letter-spacing: 0.44px;
}

.Grid-content {
	position: relative;

	@include mobile {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-column-gap: 20px;
		grid-row-gap: 20px;
	}
}
