@import "../../shared/styles/partials/colors";
@import "../../shared/styles/partials/variables";

.Main {
	position: relative;
}

.Main-content {
	width: 100%;
	max-width: $max-width-container--withOffset;
	height: 100%;
	margin: 0 auto;
	box-sizing: border-box;
	padding: 0 20px;
}
