$color-white: #FFF;
$color-black: #181818;
$color-grey--text: #515050;
$color-grey: #CED0D4;
$color-grey--border: #EBEBEB;
$color-grey--box: #CDCDCD;
$color-grey--input: #979797;
$color-grey--text: #393939;
$color-grey--placeholder: #B8B8B8;
$color-acquamarine: #5ADBCD;
$color-green: #03AA65;
$color-green--light: #E4F6EF;
$color-red: #F03D3D;
$color-orange: #FFC200;
$color-pink: #FFEAEA;
$color-blue: #5581D9;

// In progress...
