@import "../../shared/styles/partials/colors";
@import "../../shared/styles/partials/variables";
@import "../../shared/styles/partials/fonts";
@import "../../shared/styles/partials/breakpoints";

.Footer {
	background-color: $color-white;
	margin: 0 auto;
}

.Footer-content {
	width: 100%;
	height: 100%;
	margin: 0 auto;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	padding: 60px 20px;
	max-width: $max-width-container;
	border-top: 1px solid $color-grey--border;

	@include large-desktop {
		padding: 60px 0;
	}
}

.Footer-logo {
	font-size: 1.5rem;
	font-weight: 600;
	color: $color-black;
}

.Footer-credits {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-top: 1px solid $color-grey--border;
	padding: 60px 20px;
	margin: 0 auto;
	max-width: $max-width-container;

	@include large-desktop {
		padding: 60px 0;
	}

	> span {
		@include font-base(0.875rem, $color-black, 400, 1rem, 0.5);
	}
}
