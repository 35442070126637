@import "shared/styles/partials/colors";

body {
	margin: 0;
	padding: 0;
	min-height: 100vh;
	font-family: "Nunito", "Helvetica Neue", "Helvetica","Arial", sans-serif;
	/* mobile viewport bug fix */
	min-height: -webkit-fill-available;
	-webkit-overflow-scrolling: touch;
	background-color: #fff;
}

body.no-scroll-for-overlay {
	overflow: hidden;
}

@media (min-width: 992px) {
	body.no-scroll-for-overlay {
		overflow: auto;
	}
}

body.no-scroll-for-backdrop {
	overflow: hidden;
}
