@import "../../shared/styles/partials/colors";
@import "../../shared/styles/partials/fonts";

.Breadcrumbs {
	position: relative;
	list-style: none;
	margin: 0;
	padding: 0;
	display: flex;
	align-items: center;

	a {
		@include font-base(1rem, $color-black, 400, 1.125rem, 0.5px);
		display: inline-flex;
		text-decoration: none;
	}

	&.Breadcrumbs--default {
		.Breadcrumbs-item {
			&:last-child {
				a {
					pointer-events: none;
					cursor: default;
				}
			}
		}

	}
}

.Breadcrumbs-item {
	display: flex;
	align-items: center;

	> img {
		margin: 0 20px;
		height: 12px;
	}

	&:last-child {
		img {
			display: none;
		}
	}
}
