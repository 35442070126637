@import "../../shared/styles/partials/colors";
@import "../../shared/styles/partials/fonts";

.Log {
	position: relative;
}

.Log-title {
	@include font-base(1.25rem, $color-black, 700);
	letter-spacing: 0.28px;
	margin: 0 0 15px;
}

.Log-list {
	list-style: none;
	padding: 0;
	margin: 0;
}

.Log-item {
	display: flex;
	align-items: center;
	margin: 0 0 35px;
	border-bottom: 1px solid $color-grey--border;
	padding: 0 0 10px;
	box-sizing: border-box;

	> button {
		margin: 0 0 0 auto;
	}
}

.Log-icon {
	width: 44px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 25px 0 0;

	> img {
		margin: 0 auto;
	}
}

.Log-info {
	display: flex;
	flex-flow: column;

	> span {
		@include font-base(1rem, $color-black, 600, 1.125rem);
		letter-spacing: 0.44px;
		display: block;
		margin: 0 0 5px;

		&:last-child {
			margin: 0;
			font-weight: 400;
			font-size: 0.875rem;
		}
	}
}
