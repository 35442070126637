@import "../../shared/styles/partials/colors";
@import "../../shared/styles/partials/fonts";

.FileUploader {
	position: relative;
	background-color: transparent;
	border: 0;
	padding: 0;
	margin: 0;
	cursor: pointer;
	width: 100%;

	&.FileUploader--fullWidth {
		.FileUploader-content {
			width: 100%;
			height: 190px;
		}
	}
}

.FileUploader-title {
	margin: 0 0 25px;
	@include font-base(1rem, $color-black, 600);
	letter-spacing: 0.44px;
}

.FileUploader-content {
	border-radius: 16px;
	flex-shrink: 0;
	height: 244px;
	//width: 300px;

	> button {
		width: 100%;
		height: 100%;
	}
}
