@import "../../shared/styles/partials/fonts";
@import "../../shared/styles/partials/colors";

.SwitchRow {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px 0;
	border-bottom: 1px solid $color-grey--border;

	&.SwitchRow--noBorder {
		border-bottom: 0;
		padding: 15px 0;
	}
}

.SwitchRow-text {
	@include font-base(1rem, $color-black, 600);
	letter-spacing: 0.44px;
}

.SwitchRow-input {
	position: relative;
}
