@import "../../shared/styles/partials/fonts";
@import "../../shared/styles/partials/colors";
@import "../../shared/styles/partials/breakpoints";

.OutlineButton {
	@include font-base(1rem, $color-black, 600, 1.25rem, 0.5);
	cursor: pointer;
	background-color: transparent;
	white-space: nowrap;
	padding: 13px 26px;
	border-radius: 4px;
	border: 1px solid $color-black;

	@include mobile {
		min-width: 190px;
	}

	&:focus {
		outline: 0;
		box-shadow: none;
	}

	&:disabled {
		transition: opacity 300ms;
		opacity: 0.3;
		pointer-events: none;
	}
}
