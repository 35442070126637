@import "../../shared/styles/partials/colors";
@import "../../shared/styles/partials/fonts";
@import "../../shared/styles/partials/variables";
@import "../../shared/styles/partials/breakpoints";
@import "../../shared/styles/partials/utilities";

.PetTile {
	position: relative;
	width: 100%;
	border-radius: 16px;
	overflow: hidden;
	display: inline-flex;
	height: 240px;
	box-sizing: border-box;
	margin: 0 0 20px;
	text-decoration: none;
	border: 0;
	text-align: left;

	@include mobile {
		margin: 0;
	}

	@include large-desktop {
		&:hover {
			.PetTile-overlay {
				@include background-opacity($color-black, 0.5);
			}
		}
	}
}

.PetTile-background {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 16px;
	z-index: 1;
}

.PetTile-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 16px;
	transition: background-color 300ms;
	@include background-opacity($color-black, 0.6);
	z-index: 2;
}

.PetTile-image {
	object-fit: cover;
	width: 100%;
	height: 100%;
	border-radius: 16px;
	position: absolute;
	top: 0;
	left: 0;
}

.PetTile-data {
	z-index: 3;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	padding: 25px;
	box-sizing: border-box;
	display: flex;
	flex-flow: column;
	justify-content: flex-end;
}

.PetTile-name {
	@include font-base(1.5rem, $color-white, 700);
	letter-spacing: 0.38px;
	margin: 0 0 5px;
}

.PetTile-description {
	@include font-base(0.875rem, $color-white, 400);
	display: block;
	margin: 0 0 8px;
	letter-spacing: 0.5px;
	text-transform: capitalize;
}

.PetTile-extra {
	@include font-base(0.875rem, $color-white, 400);
	margin: 0;
	letter-spacing: 0.5px;
}
