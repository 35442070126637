@import "../../shared/styles/partials/colors";
@import "../../shared/styles/partials/breakpoints";
@import "../../shared/styles/partials/fonts";

.ServiceName {
	position: relative;
	display: flex;
	align-items: center;

	> img {
		margin: 0 16px 0 0;
	}
}

.ServiceName-label {
	display: inline-flex;
	@include font-base(1.25rem, $color-black, 700);
	letter-spacing: 0.38px;

	@include tablet {
		@include font-base(1.625rem, $color-black, 700);
		letter-spacing: 0.5px;
	}
}
