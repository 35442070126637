@import "../../shared/styles/partials/breakpoints";
@import "../../shared/styles/partials/fonts";
@import "../../shared/styles/partials/colors";
@import "../../shared/styles/partials/variables";

.ThreeColumnsLayout {
	position: relative;
	padding: 20px 0;
	width: 100%;
	display: flex;
	flex-flow: column;

	@include desktop {
		padding: 32px 0;
		flex-flow: row;
		justify-content: space-between;
	}
}

.ThreeColumnsLayout-first {
	display: none;
	order: 3;

	@include desktop {
		order: 1;
		display: inline-flex;
		height: 100%;
		width: 25%;
		max-width: 270px;
	}
}

.ThreeColumnsLayout-second {
	height: 100%;
	display: flex;
	flex-flow: column;
	max-width: 625px;
	order: 3;

	> button {
		width: 100%;
		margin: 0 0 20px;
	}

	@include desktop {
		order: 2;
		width: calc(55% - 50px);
		margin: 0 25px;
	}
}

.ThreeColumnsLayout-third {
	width: 100%;
	order: 1;

	@include desktop {
		order: 2;
		height: 100%;
		width: 20%;
		max-width: 225px;
	}
}
