@import "../../shared/styles/partials/breakpoints";
@import "../../shared/styles/partials/fonts";
@import "../../shared/styles/partials/colors";
@import "../../shared/styles/partials/variables";

.Content {
	position: relative;
	max-width: $max-width-content;
	width: 100%;
	margin: 32px auto 48px;

	&.Content--noMargins {
		margin: 0 auto;
	}
}

.Content-breadcrumbs {
	margin: 0 0 30px;
	position: relative;
}

.Content-title {
	@include font-base(2rem, $color-black, 700, 2.25rem, 0.48px);
	margin: 0 0 45px;
	position: relative;
}
