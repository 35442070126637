@import "../../shared/styles/partials/fonts";
@import "../../shared/styles/partials/colors";

.RadioGroup {
	position: relative;
	margin: 0 0 40px;

	[type="radio"]:checked,
	[type="radio"]:not(:checked) {
		position: absolute;
		left: -9999px;
	}
	[type="radio"]:checked + label,
	[type="radio"]:not(:checked) + label
	{
		position: relative;
		padding-left: 28px;
		cursor: pointer;
		display: inline-block;
		@include font-base(0.875rem, $color-black, 500, 1.325rem);
	}
	[type="radio"]:checked + label:before,
	[type="radio"]:not(:checked) + label:before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 20px;
		height: 20px;
		border: 1px solid $color-grey--input;
		border-radius: 100%;
		background: $color-white;
		box-sizing: border-box;
	}
	[type="radio"]:checked + label:after,
	[type="radio"]:not(:checked) + label:after {
		content: '';
		width: 12px;
		height: 12px;
		background: $color-green;
		position: absolute;
		top: 4px;
		left: 4px;
		border-radius: 100%;
		-webkit-transition: all 0.2s ease;
		transition: all 0.2s ease;
	}
	[type="radio"]:not(:checked) + label:after {
		opacity: 0;
		transform: scale(0);
	}
	[type="radio"]:checked + label:after {
		opacity: 1;
		transform: scale(1);
	}
}

.RadioGroup-title {
	margin: 0 0 20px;
	@include font-base(1rem, $color-black, 600);
	letter-spacing: 0.44px;

	&.RadioGroup-title--withDescription {
		margin: 0 0 10px;
	}
}

.RadioGroup-description {
	margin: 0 0 20px;
	@include font-base(0.875rem, $color-black, 400);
	letter-spacing: 0.5px;
}

.RadioGroup-list {
	list-style: none;
	margin: 0;
	padding: 0;

	display: flex;
	align-items: center;

	li {
		display: flex;
		align-items: center;
		margin: 0 20px 0 0;

		&:last-child {
			margin: 0;
		}
	}
}
