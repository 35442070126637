@import "../../shared/styles/partials/colors";
@import "../../shared/styles/partials/fonts";
@import "../../shared/styles/partials/utilities";
@import "../../shared/styles/partials/breakpoints";

.WizardProgress {
	position: relative;
	width: 100%;
}

.WizardProgress-bar {
	width: 100%;
	position: relative;
	height: 16px;
	border-radius: 8px;
	margin: 5px 0 10px;

	@include desktop {
		margin: 5px 0 20px;
	}

	@include background-opacity($color-green, 0.08);

	> div {
		background-color: $color-green;
		border-radius: 8px;
		height: 100%;
		position: absolute;
		left: 0;
		bottom: 0;

		transition: all 300ms;
	}
}

.WizardProgress-text {
	@include font-base(0.875rem, $color-black, 600, 1rem);
	display: flex;
	align-items: center;
	justify-content: flex-end;
	letter-spacing: 0.43px;
	margin: 0 0 30px;

	> strong {
		font-size: 1rem;
		letter-spacing: 0.9px;
		margin: 0 3px 0 0;
	}

	@include tablet {
		justify-content: flex-start;
		@include font-base(1.125rem, $color-black, 600, 2.25rem);

		> strong {
			font-size: 2.25rem;
			margin: 0 5px 0 0;
		}
	}

	@include desktop {
		margin: 0 0 40px;
	}
}

.WizardProgress-submit {
	display: none;

	@include desktop {
		display: inline-flex;
	}
}
