@import "../../shared/styles/partials/fonts";
@import "../../shared/styles/partials/colors";
@import "../../shared/styles/partials/breakpoints";
@import "../../shared/styles/partials/variables";

.Select {
  position: relative;

  > select {
    // A reset of styles, including removing the default dropdown arrow
    appearance: none;
    -webkit-appearance: none;
    margin: 0;
    background-color: $color-white;
    border: 1px solid $color-grey--input;
    padding: 11px 0 11px 24px;
    border-radius: 6px;
    width: 100%;
    min-width: 100px;
    height: 54px;
    @include font-base(1rem, $color-black, 400, 1rem);
	  letter-spacing: 0.5px;
    cursor: pointer;
    box-sizing: border-box;
    outline: none;

    &::-ms-expand {
      display: none;
    }

	  /*
    &:focus + .Select-focus {
      position: absolute;
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      border: 1px solid $color-green;
      border-radius: 8px;
    }*/
  }

  > img {
    position: absolute;
    right: 24px;
    top: 22px;
  }
}
