@import "../../shared/styles/partials/breakpoints";
@import "../../shared/styles/partials/fonts";
@import "../../shared/styles/partials/colors";
@import "../../shared/styles/partials/variables";

.Account {
	position: relative;
	padding: 40px 0;
	max-width: 1040px;
	margin: 0 auto;
}

.Account-header {
	margin: 0 0 50px;
	position: relative;

	> h1 {
		text-align: center;
	}
}

.Account-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 0 auto 100px;

	> div {
		min-width: 320px;
	}
}

.Account-footer {
	margin: 40px auto 0;
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;

	> h4 {
		@include font-base(1.125rem, $color-black, 600, 1.375rem, 0.64);
		margin: 0 auto 20px;
	}
}

.Account-loader {
	min-height: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
}
