@import '../../shared/styles/partials/utilities';
@import '../../shared/styles/partials/colors';

.Backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  @include background-opacity($color-black, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
  @include fadeIn(300ms);

  &.Backdrop--withOverflow {
    overflow-y: auto;
  }
}
