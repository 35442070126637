@import "../../shared/styles/partials/colors";
@import "../../shared/styles/partials/fonts";
.input-range__slider {
	background-color: $color-green;
	border: 0;
}
.input-range__track--active {
	background-color: $color-green;
}
.input-range__label-container {
	top: -3px;
}
.input-range__label {
	@include font-base(0.875rem, $color-black, 400);
}
