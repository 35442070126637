@import "../../shared/styles/partials/colors";
@import "../../shared/styles/partials/fonts";

.PasswordUpdate {
	position: relative;
	border-bottom: 1px solid $color-grey--border;
	box-sizing: border-box;
	width: 100%;
	justify-content: space-between;
	margin: 0 0 20px;

	&.PasswordUpdate--hasForm {
		padding: 0 0 25px;
	}

	&.PasswordUpdate--readOnly {
		border: 0;

		.PasswordUpdate-data {
			> label {
				font-weight: 700;
			}
		}
	}
}

.PasswordUpdate-data {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin: 0 0 25px;

	> label {
		@include font-base(1rem, $color-black, 600);
		letter-spacing: 0.44px;
		display: block;
	}

	> span {
		@include font-base(0.875rem, $color-black, 400);
		letter-spacing: 0.44px;
		display: block;
	}
}

.PasswordUpdate-form {
	position: relative;
	width: 100%;

	> footer {
		display: flex;
		justify-content: flex-end;

		> button {
			margin: 0 0 0 16px;
		}
	}
}
