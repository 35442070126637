@import "../../shared/styles/partials/colors";

.Loader {
  display: flex;
  position: relative;
	justify-content: center;
  width: 60px;
  height: 60px;
	margin: 0 auto;

  div {
    position: absolute;
    border: 4px solid $color-green;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    &:nth-child(2) {
      animation-delay: -0.5s;
    }
  }
}

@keyframes lds-ripple {
  0% {
    top: 24px;
    left: 24px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0;
    left: 0;
    width: 48px;
    height: 48px;
    opacity: 0;
  }
}
