@import "../../shared/styles/partials/colors";
@import "../../shared/styles/partials/fonts";
@import "../../shared/styles/partials/variables";
@import "../../shared/styles/partials/breakpoints";

.LinkBox {
	position: relative;
	box-sizing: border-box;
	min-height: 180px;
	width: 100%;
	border: 1px solid $color-grey--border;
	border-radius: 8px;
	background-color: $color-white;
	box-shadow: $default-box-shadow;
	padding: 32px 40px;
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: flex-start;
	margin: 0 auto 20px;
	cursor: pointer;
	transition: all 300ms;
	text-decoration: none;

	&.is-disabled {
		pointer-events: none;
		cursor: default;
		opacity: 0.5;
	}

	@include large-desktop {
		&:hover {
			transform: scale(1.04);
		}
	}

	@include mobile {
		margin: 0;
	}
}

.LinkBox-icon {
	margin: 0 0 18px;
}

.LinkBox-header {
	display: flex;
	align-items: center;
	margin: 0 0 12px;
}

.LinkBox-title {
	@include font-base(1.125rem, $color-black, 600, 1.375rem, 0.64);
	margin: 0 20px 0 0;
}

.LinkBox-description {
	@include font-base(0.875rem, $color-black, 400, 1rem, 0.5);
	margin: 0;
}
