@import "../../shared/styles/partials/fonts";
@import "../../shared/styles/partials/colors";

.Checkbox {
	display: flex;
	align-items: center;

	&.Checkbox--column {
		flex-flow: column;
	}

	[type="checkbox"]:checked,
	[type="checkbox"]:not(:checked) {
		position: absolute;
		left: -9999px;
	}
	[type="checkbox"]:checked + label,
	[type="checkbox"]:not(:checked) + label
	{
		position: relative;
		padding-left: 28px;
		cursor: pointer;
		display: inline-block;
		@include font-base(0.875rem, $color-black, 500, 1.325rem);
	}
	[type="checkbox"]:checked + label:before,
	[type="checkbox"]:not(:checked) + label:before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 20px;
		height: 20px;
		border: 1px solid $color-grey--input;
		border-radius: 2px;
		background: $color-white;
		box-sizing: border-box;
	}
	[type="checkbox"]:checked + label:after,
	[type="checkbox"]:not(:checked) + label:after {
		content: '';
		width: 12px;
		height: 12px;
		background: $color-green;
		position: absolute;
		top: 4px;
		left: 4px;
		border-radius: 2px;
		-webkit-transition: all 0.2s ease;
		transition: all 0.2s ease;
	}
	[type="checkbox"]:not(:checked) + label:after {
		opacity: 0;
		transform: scale(0);
	}
	[type="checkbox"]:checked + label:after {
		opacity: 1;
		transform: scale(1);
	}
}
