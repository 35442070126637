@import "../../shared/styles/partials/fonts";
@import "../../shared/styles/partials/colors";
@import "../../shared/styles/partials/breakpoints";

.GreenButton {
	@include font-base(1rem, $color-white, 600, 1.25rem, 0.5);
	border: 0;
	cursor: pointer;
	background-color: $color-green;
	//white-space: nowrap;
	padding: 13px 26px;
	border-radius: 4px;

	@include mobile {
		min-width: 190px;
	}

	&:focus {
		outline: 0;
		box-shadow: none;
	}

	&.GreenButton--secondary {
		background-color: transparent;
		color: $color-green;
		padding: 0;
		border-radius: 0;
		min-width: auto
	}

	&.GreenButton--fullWidth {
		width: 100%;
	}

	&:disabled {
		transition: opacity 300ms;
		opacity: 0.3;
		pointer-events: none;
	}
}
