@import "../../shared/styles/partials/colors";
@import "../../shared/styles/partials/fonts";
@import "../../shared/styles/partials/utilities";

.Input {
	position: relative;
	margin: 0 0 30px;

	&.Input--noMargin {
		margin: 0;
	}

	&.is-focused {
		.Input-data {
			border-color: $color-green;

			&:disabled {
				border-color: $color-grey--input;
			}
		}
	}
}

.Input-data {
	border: 1px solid $color-grey--input;
	padding: 10px 24px;
	height: 60px;
	box-sizing: border-box;
	border-radius: 6px;
	display: flex;
	flex-flow: column;
	transition: all 300ms;

	> label {
		display: block;
		@include font-base(0.688rem, $color-black, 400, 1.125rem);
		letter-spacing: 0.39px;
	}

	> input {
		-webkit-appearance: none;
		border: 0;
		padding: 0;
		margin: 0;
		@include font-base(1rem, $color-black, 500, 1rem);
		letter-spacing: 0.57px;

		@include input-placeholder {
			@include font-base(1rem, $color-grey--placeholder, 500, 1rem);
		}

		&:focus {
			outline: 0;
			box-shadow: none;
		}

		/* Chrome, Safari, Edge, Opera */
		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		/* Firefox */
		&[type=number] {
			-moz-appearance: textfield;
		}
	}

	&.Input-data--error {
		border-color: $color-red;
		background-color: $color-pink;
		transition: border-color 300ms;

		> input {
			background-color: $color-pink;
		}

		> label {
			color: $color-red;
			transition: color 300ms;
		}
	}

	&.is-disabled {
		opacity: 0.4;

		> input {
			pointer-events: none;
		}
	}
}

.Input-feedback {
	@include font-base(0.875rem, $color-red, 500);
	letter-spacing: 0.5px;
	display: flex;
	align-items: center;
	width: 100%;
	margin: 8px 0;
	min-height: 20px;

	> img {
		margin: 0 5px 0 0;
	}
}
