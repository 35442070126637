@import "../../shared/styles/partials/fonts";
@import "../../shared/styles/partials/colors";

.ModalUpload {
	position: relative;
}

.ModalUpload-input {
	margin: 30px 0 0;

	> div {
		margin: 0;
	}
}
