@import "../../shared/styles/partials/breakpoints";
@import "../../shared/styles/partials/fonts";
@import "../../shared/styles/partials/colors";
@import "../../shared/styles/partials/variables";

.ExperienceAndSkills {
	position: relative;
	margin: 0 auto;
	width: 100%;
	flex-shrink: 0;
	min-height: 400px;
}

.ExperienceAndSkills-content {
	position: relative;
}

.ExperienceAndSkills-footer {
	display: flex;
	justify-content: space-between;
	margin: 40px 0;

	> button {
		width: calc(50% - 10px);

		@include desktop {
			width: fit-content;
		}

		@include desktop {
			width: fit-content;
		}
	}
}

.ExperienceAndSkills-map {
	margin: 0 0 30px;
}
