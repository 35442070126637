@import "../../shared/styles/partials/fonts";
@import "../../shared/styles/partials/colors";
@import "../../shared/styles/partials/breakpoints";

.RangeSlider {
	position: relative;
	margin: 0 0 50px;
}

.RangeSlider-title {
	margin: 0 0 30px;
	@include font-base(1rem, $color-black, 600);
	letter-spacing: 0.44px;

	&.RangeSlider-title--withDescription {
		margin: 0 0 10px;
	}
}

.RangeSlider-description {
	margin: 0 0 30px;
	@include font-base(0.875rem, $color-black, 400);
	letter-spacing: 0.5px;
}

.RangeSlider-content {
	position: relative;
}
