@import '../../shared/styles/partials/colors';
@import '../../shared/styles/partials/fonts';

.ProfileSentSuccessfully {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-flow: column;
	padding: 10px;
}

.ProfileSentSuccessfully-image {
	margin: 0 auto 10px;
}

.ProfileSentSuccessfully-subtitle {
	@include font-base(1.5rem, $color-black, 600);
	text-align: center;
	margin: 0 auto 30px;
}

.ProfileSentSuccessfully-description {
	@include font-base(1rem, $color-black, 400, 1.5rem);
	letter-spacing: 0.5px;
	text-align: center;
	margin: 0;

	> strong {
		font-weight: 600;
	}
}
