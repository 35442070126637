@import "../../shared/styles/partials/colors";
@import "../../shared/styles/partials/fonts";
@import "../../shared/styles/partials/breakpoints";

.PhotoUploader {
	position: relative;

	&.PhotoUploader--fullWidth {
		.PhotoUploader-content {
			width: 100%;
			height: 250px;

			@include tablet {
				max-width: 100%
			}
		}
	}
}

.PhotoUploader-title {
	margin: 0 0 25px;
	@include font-base(1rem, $color-black, 600);
	letter-spacing: 0.44px;
}

.PhotoUploader-content {
	border-radius: 16px;
	flex-shrink: 0;
	height: 200px;
	width: 100%;
	cursor: pointer;

	@include tablet {
		max-width: 200px;
	}

	> img {
		width: 100%;
		height: 100%;
		border-radius: 16px;
		object-fit: cover;
		border: 2px solid $color-white;
		transition: all 250ms;
		box-sizing: border-box;

		@include large-desktop {
			&:hover {
				border-color: $color-green;
			}
		}
	}

	> button {
		width: 100%;
		height: 100%;
	}
}

.PhotoUploader-input {
	visibility: hidden;
	position: absolute;
	top: 0;
	left: 0;
	width: 1px;
	height: 1px;
}
