@import "../../shared/styles/partials/colors";
@import "../../shared/styles/partials/fonts";

.NavigationWizard {
	position: relative;
}

.NavigationWizard-title {
	@include font-base(1.125rem, $color-black, 700, 1.75rem);
	letter-spacing: 0.5px;
	margin: 0 0 25px;
}

.NavigationWizard-items {
	list-style: none;
	padding: 0;
	margin: 0;
	border: 1px solid $color-grey--input;
	border-radius: 6px;
}

.NavigationWizard-item {
	padding: 0 25px;
	position: relative;
	box-sizing: border-box;
	border-bottom: 1px solid $color-grey--input;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 55px;

	&:last-child {
		border: 0;
	}

	> span {
		@include font-base(0.875rem, $color-black, 400);
		letter-spacing: 0.5px;
		margin: 0 20px 0 0;
	}

	> img {
		margin: 0 0 0 auto;
	}

	&.is-active {
		background-color: $color-green;
		cursor: default;

		> span {
			color: $color-white;
			font-weight: 700;
		}
	}

	&.is-disabled {
		color: $color-grey--input;
	}
}

