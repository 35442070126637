@import "../../shared/styles/partials/colors";
@import "../../shared/styles/partials/fonts";

.Block {
	position: relative;
}

.Block-title {
	@include font-base(1.25rem, $color-black, 700);
	letter-spacing: 0.28px;
	display: block;
	margin: 0 0 10px;
}

.Block-description {
	@include font-base(0.875rem, $color-black, 400);
	letter-spacing: 0.44px;
	margin: 0;
}

.Block-content {
	position: relative;
	margin: 20px 0 0;
}
