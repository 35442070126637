@import "../../shared/styles/partials/colors";
@import "../../shared/styles/partials/fonts";
@import "../../shared/styles/partials/variables";
@import "../../shared/styles/partials/breakpoints";

.LinksList {
	position: relative;
}

.LinksList-title {
	@include font-base(1.25rem, $color-black, 700, 1.5rem, 0.5);
	margin: 0 0 30px;
}

.LinksList-items {
	list-style: none;
	margin: 0;
	padding: 0;

	a {
		@include font-base(0.875rem, $color-black, 400, 1rem, 0.5);
		display: block;
		text-decoration: none;
		margin: 0 0 30px;
	}

	> li {
		&:last-child {
			a {
				margin: 0;
			}
		}
	}
}
