@import "../../shared/styles/partials/breakpoints";
@import "../../shared/styles/partials/fonts";
@import "../../shared/styles/partials/colors";
@import "../../shared/styles/partials/variables";

.YourHome {
	position: relative;
	margin: 0 auto;
	width: 100%;
	flex-shrink: 0;
	min-height: 400px;
}

.YourHome-content {
	position: relative;
}

.YourHome-map {
	margin: 0 0 30px;
}

