@import "../../shared/styles/partials/colors";
@import "../../shared/styles/partials/fonts";
@import "../../shared/styles/partials/variables";
@import "../../shared/styles/partials/breakpoints";
@import "../../shared/styles/partials/utilities";

.EmptyBox {
	position: relative;
	width: 100%;
	height: 100%;
	border-radius: 16px;
	overflow: hidden;
	box-sizing: border-box;
	margin: 0 0 20px;
	text-decoration: none;
	border: 2px dashed $color-green;
	background: transparent;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-flow: column;

	@include mobile {
		margin: 0;
	}
}

.EmptyBox-image {
	margin: 0 0 20px;
}

.EmptyBox-text {
	display: block;
	@include font-base(0.875rem, $color-green, 400);
}
