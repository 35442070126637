@import '../../shared/styles/partials/utilities';
@import '../../shared/styles/partials/colors';
@import '../../shared/styles/partials/breakpoints';
@import '../../shared/styles/partials/fonts';

.Modal {
	background-color: $color-white;
	position: relative;
	border-radius: 12px;

	@include tablet {
		width: 560px;
		min-height: 200px;
	}
}

.Modal-header {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	border-bottom: 1px solid $color-grey--border;
	padding: 25px;
	box-sizing: border-box;
}

.Modal-title {
	margin: 0;
	@include font-base(1rem, $color-grey--text, 700);
	letter-spacing: 0.4px;
}

.Modal-close {
	background-color: transparent;
	border: 0;
	padding: 0;
	margin: 0;
	position: absolute;
	right: 25px;
	top: 25px;

	&:focus {
		outline: 0;
		box-shadow: none;
	}
}

.Modal-content {
	padding: 25px;
	position: relative;
	box-sizing: border-box;
}

.Modal-footer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	border-top: 1px solid $color-grey--border;
	padding: 25px;
	box-sizing: border-box;

	> button {
		text-transform: capitalize;
		&:last-child {
			margin: 0 0 0 auto;
		}
	}
}
