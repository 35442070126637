@import "../../shared/styles/partials/breakpoints";
@import "../../shared/styles/partials/fonts";
@import "../../shared/styles/partials/colors";
@import "../../shared/styles/partials/variables";

.AboutYou {
	position: relative;
	margin: 0 auto;
	width: 100%;
	flex-shrink: 0;
	min-height: 400px;
}

.AboutYou-photo {
	margin: 0 0 30px;

	@include tablet {
		margin: 0 0 40px;
	}
}

.AboutYou-content {
	position: relative;
}

.AboutYou-footer {
	display: flex;
	justify-content: flex-end;
	margin: 40px 0;
	width: 100%;

	> button {
		width: 100%;
	}

	@include mobile {
		justify-content: flex-end;

		> button {
			width: fit-content;
		}
	}
}

